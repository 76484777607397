import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEvents } from '../hooks/useEvents';
import dayjs from 'dayjs';
import {filterFutureEvents, filterPastEvents} from "../utils/eventUtils";

const Events: React.FC = () => {
    const { events, loading } = useEvents();
    const navigate = useNavigate();
    const [showUpcoming, setShowUpcoming] = useState(true);

    const upcomingEvents = filterFutureEvents(events);
    const pastEvents = filterPastEvents(events);

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                <Typography variant="h6">Loading events...</Typography>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                maxWidth: '1200px',
                margin: '0 auto',
                padding: '40px 20px',
            }}
        >
            {/* Switch between Upcoming and Past Events */}
            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '20px', padding: '5px' }}>
                <Button
                    variant="outlined"
                    onClick={() => setShowUpcoming(true)}
                    sx={{
                        marginRight: '10px',
                        backgroundColor: showUpcoming ? 'black' : 'transparent',
                        color: 'white',
                        borderColor: showUpcoming ? 'white' : 'black',
                        textTransform: 'uppercase',
                        '&:hover': {
                            backgroundColor: showUpcoming ? 'black' : 'transparent',
                        },
                        borderWidth: showUpcoming ? '2px' : '1px',
                    }}
                >
                    Upcoming Events
                </Button>
                <Button
                    variant="outlined"
                    onClick={() => setShowUpcoming(false)}
                    sx={{
                        backgroundColor: !showUpcoming ? 'black' : 'transparent',
                        color: 'white',
                        borderColor: !showUpcoming ? 'white' : 'black',
                        textTransform: 'uppercase',
                        '&:hover': {
                            backgroundColor: !showUpcoming ? 'black' : 'transparent',
                        },
                        borderWidth: !showUpcoming ? '2px' : '1px',
                    }}
                >
                    Past Events
                </Button>
            </Box>

            {/* Display either Upcoming or Past Events */}
            {showUpcoming ? (
                <EventList events={upcomingEvents} navigate={navigate} />
            ) : (
                <EventList events={pastEvents} navigate={navigate} />
            )}
        </Box>
    );
};

// Reusable Event List component
const EventList = ({ events, navigate }: { events: any[]; navigate: any }) => {
    if (events.length === 0) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                <Typography variant="h6">No events found.</Typography>
            </Box>
        );
    }

    return (
        <>
            {events.map((event) => (
                <Box
                    key={event.id}
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        alignItems: { xs: 'flex-start', md: 'center' },
                        marginBottom: '40px',
                        backgroundColor: '#101010', // Keep the dark gray background all the time
                        borderRadius: '8px',
                        overflow: 'hidden',
                    }}
                >
                    <Box
                        component="img"
                        src={event.posterimgurl}
                        alt={event.name}
                        sx={{
                            width: { xs: '100%', md: '40%' },
                            height: 'auto',
                            objectFit: 'cover',
                        }}
                    />
                    <Box
                        sx={{
                            padding: '20px',
                            flex: 1,
                            color: 'text.primary',
                            textAlign: { xs: 'left', md: 'left' },
                        }}
                    >
                        <Typography variant="h2" sx={{ mb: 2 }}>
                            {event.name}
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            {event.city}
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            {event.date === 'TBA' ? 'TBA' : dayjs(event.date).format('DD MMM YYYY')}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 4 }}>
                            {event.description}
                        </Typography>
                        <Button
                            variant="outlined"
                            sx={{
                                backgroundColor: 'black',
                                color: 'white',
                                borderColor: 'white',
                                textTransform: 'uppercase',
                                '&:hover': {
                                    backgroundColor: 'white',
                                    color: 'black',
                                    borderColor: 'black',
                                },
                            }}
                            onClick={() => navigate(`/events/${event.id}`)}
                        >
                            More Info
                        </Button>
                    </Box>
                </Box>
            ))}
        </>
    );
};

export default Events;
