import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Typography, Chip } from '@mui/material';
import { useEvents } from '../hooks/useEvents';
import LoadingSpinner from '../components/common/LoadingSpinner';
import dayjs from "dayjs";

const EventDetails: React.FC = () => {
    const { eventId } = useParams<{ eventId: string }>();
    const { events, loading } = useEvents();
    const event = events.find((e) => e.id.toString() === eventId);

    useEffect(() => {
        if (event?.viewpageconversion) {
            window.gtag('event', 'conversion', {
                'send_to': event.viewpageconversion
            });
        }
    }, [event]);

    if (loading) return <LoadingSpinner />;
    if (!event) return <Typography variant="h5">Event not found</Typography>;

    return (
        <Box sx={{ maxWidth: '1200px', margin: '0 auto', padding: '40px 20px' }}>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: '20px', marginBottom: '40px' }}>
                {/* Poster */}
                <Box
                    component="img"
                    src={event.posterimgurl}
                    alt={event.name}
                    sx={{
                        width: { xs: '100%', md: '40%' },
                        objectFit: 'cover',
                        alignSelf: 'flex-start', // Make sure the image aligns with the top of the title
                    }}
                />

                {/* Event Details */}
                <Box sx={{ flex: 1, textAlign: { xs: 'center', md: 'left' }, alignSelf: 'flex-start' }}>
                    <Typography variant="h3" sx={{ mb: 4, fontWeight: 700 }}>{event.name}</Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>Date</Typography>
                    <Typography variant="body1" sx={{ mb: 3 }}>
                        {event.date === 'TBA' ? 'TBA' : dayjs(event.date).format('DD MMM YYYY')}
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>Location</Typography>
                    <Typography variant="body1" sx={{ mb: 3 }}>{event.city}</Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>Description</Typography>
                    <Typography variant="body2" sx={{ mb: 3 }}>{event.description}</Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>Lineup</Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, justifyContent: { xs: 'center', md: 'flex-start' } }}>
                        {event.lineup.map((artist, index) => (
                            <Chip key={index} label={artist} sx={{ backgroundColor: '#1d1d1d', color: 'white' }} />
                        ))}
                    </Box>
                    <Button
                        variant="outlined"
                        sx={{
                            backgroundColor: 'black',
                            color: 'white',
                            borderColor: 'white',
                            mt: 4,
                            '&.Mui-disabled': {
                                backgroundColor: 'gray',
                                color: 'lightgray',
                                borderColor: 'gray',
                            },
                        }}
                        onClick={() => {
                            // Trigger the Google Tag conversion event if buyTicketButtonConversion is available
                            if (event?.buyticketbuttonconversion) {
                                window.gtag('event', 'conversion', {
                                    'send_to': event.buyticketbuttonconversion
                                });
                            }
                            window.open(event.ticketsurl, '_blank');
                        }}
                        disabled={!event.ticketsurl || event.ticketsurl === '#'}
                    >
                        Buy Tickets
                    </Button>

                </Box>
            </Box>
        </Box>
    );
};

export default EventDetails;
