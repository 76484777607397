import axios, { AxiosError } from 'axios';
import { config } from '../config';

export const subscribeNewsletter = async (email: string) => {
    try {
        const response = await axios.post(`${config.apiBaseUrl}/api/subscribers/subscribe`, { email });
        return { success: true };
    } catch (error) {
        // Check if the error is an AxiosError
        if (error instanceof AxiosError && error.response) {
            return { success: false, message: error.response.data?.error || 'Failed to subscribe' };
        }
        // Fallback for unexpected errors
        return { success: false, message: 'An unexpected error occurred' };
    }
};
