import React, {useContext, useState} from 'react';
import { Box, Button, TextField, Typography, Alert } from '@mui/material';
import axios from 'axios';
import {config} from "../config";
import { AuthContext } from '../context/AuthContext';

const Login: React.FC = () => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string>('');
    const auth = useContext(AuthContext);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError('');

        try {
            const response = await axios.post(config.apiBaseUrl + '/api/auth', {
                username,
                password,
            }, {
                headers: { 'Content-Type': 'application/json' },
            });

            if (response.data.token) {
                auth?.login(response.data.token)
            } else {
                setError('Login failed. Please try again.');
            }
        } catch (err) {
            console.log(err);  // Log the error for better debugging
            setError('Incorrect username or password.');
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                backgroundColor: 'background.default',
                padding: 3,
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    maxWidth: '450px',  // Make the box wider
                    backgroundColor: 'background.paper',
                    padding: '40px',  // Add more padding for better spacing
                    borderRadius: '12px',
                    boxShadow: '0 8px 30px rgba(0, 0, 0, 0.4)',  // Stronger shadow for more depth
                    textAlign: 'center',
                }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        color: 'text.primary',
                        marginBottom: '30px',
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                    }}
                >
                    Login
                </Typography>

                {error && (
                    <Alert severity="error" sx={{ marginBottom: '20px' }}>
                        {error}
                    </Alert>
                )}

                <form onSubmit={handleSubmit}>
                    {/* Username Input */}
                    <TextField
                        label="Username"
                        variant="outlined"
                        fullWidth
                        required
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        sx={{
                            marginBottom: '20px',
                            input: { color: 'white' },  // White text inside input
                            label: { color: 'text.primary' },  // White label
                            fieldset: { borderColor: 'white' },  // White borders
                            '& .MuiOutlinedInput-root:hover fieldset': { borderColor: 'white' },  // White on hover
                        }}
                    />

                    {/* Password Input */}
                    <TextField
                        label="Password"
                        type="password"
                        variant="outlined"
                        fullWidth
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        sx={{
                            marginBottom: '30px',
                            input: { color: 'white' },
                            label: { color: 'text.primary' },
                            fieldset: { borderColor: 'white' },
                            '& .MuiOutlinedInput-root:hover fieldset': { borderColor: 'white' },
                        }}
                    />

                    {/* Login Button */}
                    <Button
                        type="submit"
                        fullWidth
                        sx={{
                            padding: '12px',
                            backgroundColor: 'black',  // Black background
                            color: 'white',  // White text
                            borderColor: 'white',  // White border
                            borderWidth: '2px',
                            borderStyle: 'solid',
                            textTransform: 'uppercase',
                            '&:hover': {
                                backgroundColor: 'white',  // White background on hover
                                color: 'black',  // Black text on hover
                                borderColor: 'black',  // Black border on hover
                            },
                        }}
                    >
                        Login
                    </Button>
                </form>
            </Box>
        </Box>
    );
};

export default Login;
