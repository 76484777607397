import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const LoadingSpinner: React.FC = () => (
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 'calc(100vh - 200px)',  // Ensures it's centered on the page
        }}
    >
        <CircularProgress size={60} thickness={4} />  {/* Increased size for better visibility */}
    </Box>
);

export default LoadingSpinner;
