import React from 'react';
import {Box, Typography, Link, Container, SvgIcon} from '@mui/material';
import { Instagram, Facebook } from '@mui/icons-material';
import { config } from '../../config';
import tikTokIcon from '../../assets/icons8-tiktok.svg'

const TikTok = (props: any) => (
    <SvgIcon {...props} viewBox="0 0 32 32">
        <path d="M16 0C7.16 0 0 7.16 0 16s7.16 16 16 16 16-7.16 16-16S24.84 0 16 0zm7.92 13.67c-.42.02-.84-.03-1.25-.15-.5-.14-.97-.35-1.42-.63-.37-.23-.72-.5-1.04-.81-.41-.43-.73-.93-.95-1.48-.24-.6-.37-1.23-.4-1.87 0-.03 0-.05-.04-.06-.03-.01-.04 0-.06.02-.68.82-1.47 1.54-2.33 2.18-.92.71-1.94 1.3-3 1.76-1.31.58-2.67.97-4.07 1.18-.01.01-.04 0-.04-.02-.02-.61-.04-1.23-.04-1.85v-.19c-.01-.05 0-.05-.06-.05-1.57 0-2.94-.06-4.3-.32-.04 0-.07-.01-.08.03-.03.39-.03.77-.03 1.16v.1c.06 3.42 2.91 6.23 6.42 6.37 1.64.06 3.19-.38 4.53-1.17 1.26-.75 2.4-1.71 3.36-2.82.89-1.02 1.61-2.14 2.14-3.33.04-.1.07-.2.1-.3.08-.3.14-.59.2-.89.02-.08.02-.16.03-.25.02-.17.04-.33.05-.5.02-.05 0-.06-.05-.06z" />
    </SvgIcon>
);

const Footer: React.FC = () => (
    <Box sx={{ backgroundColor: 'background.paper', py: 4, textAlign: 'center', color: 'text.primary', mt: 5 }}>
        <Container>
            <Typography variant="body1" sx={{ mb: 2, textTransform: 'uppercase', fontWeight: 'bold' }}>
                Find us on Social Media!
            </Typography>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', gap: 2 }}>
                <Link href={config.social_media.instagram} target="_blank" rel="noopener noreferrer" sx={{ color: 'text.primary' }}>
                    <Instagram fontSize="large" />
                </Link>
                <Link href={config.social_media.facebook} target="_blank" rel="noopener noreferrer" sx={{ color: 'text.primary' }}>
                    <Facebook fontSize="large" />
                </Link>
                <Link href={config.social_media.tiktok} target="_blank" rel="noopener noreferrer" sx={{ color: 'text.primary' }}>
                    <img src={tikTokIcon} alt="TikTok" style={{ width: '34px', height: '34px' }} /> {/* TikTok SVG */}
                </Link>
            </Box>
        </Container>
    </Box>
);

export default Footer;
