import React from 'react';
import {Routes, Route} from 'react-router-dom';
import Navbar from './components/common/Navbar';
import Footer from './components/common/Footer';
import Home from './pages/Home';
import Events from './pages/Events';
import Contact from './pages/Contact';
import Login from "./pages/Login";
import StatusPage from "./pages/StatusPage";
import AdminDashboard from "./pages/AdminDashboard";
import Shop from "./pages/Shop";
import EventDetails from "./pages/EventDetails";
import Subscribers from "./pages/Subscribers";
import EventForm from "./pages/EventForm";
import ManageEvents from "./pages/ManageEvents";
import ProtectedRoute from "./components/ProtectedRoute";

function App() {
    return (
        <div style={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>

            <Navbar/>

            <div style={{flexGrow: 1}}>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/events" element={<Events/>}/>
                    <Route path="/events/:eventId" element={<EventDetails/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/status" element={<StatusPage/>}/>
                    <Route path="/shop" element={<Shop/>}/>

                    <Route element={<ProtectedRoute/>}>
                        <Route path="/admin/dashboard" element={<AdminDashboard/>}/>
                        <Route path="/admin/subscribers" element={<Subscribers/>}/>
                        <Route path="/admin/events" element={<ManageEvents/>}/>
                        <Route path="/admin/events/new" element={<EventForm/>}/>
                        <Route path="/admin/events/edit/:eventId" element={<EventForm/>}/>
                    </Route>
                </Routes>
            </div>

            <Footer/>

        </div>
    );
}

export default App;
