import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { subscribeNewsletter } from '../../services/newsletterService';

const Newsletter: React.FC = () => {
    const theme = useTheme();
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleSubscribe = async () => {
        setMessage('');
        setError('');

        if (!email) {
            setError('Please enter a valid email.');
            return;
        }

        try {
            const response = await subscribeNewsletter(email);
            if (response.success) {
                setMessage('Successfully subscribed!');
                setEmail('');
            } else {
                setError(response.message);
            }
        } catch {
            setError('Failed to subscribe. Please try again later.');
        }
    };

    return (
        <Container maxWidth="sm" sx={{ textAlign: 'center', py: 5 }}>
            <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold', textTransform: 'uppercase' }}>
                Stay Updated!
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
                Subscribe to our newsletter for the latest events, news, and early access!
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                <TextField
                    variant="outlined"
                    placeholder="Enter your email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{ flex: 1, bgcolor: theme.palette.background.paper, borderRadius: 1 }}
                />
                <Button variant="contained" onClick={handleSubscribe}>
                    Subscribe
                </Button>
            </Box>

            {message && <Typography variant="body2" sx={{ mt: 2, color: 'success.main' }}>{message}</Typography>}
            {error && <Typography variant="body2" sx={{ mt: 2, color: 'error.main' }}>{error}</Typography>}
        </Container>
    );
};

export default Newsletter;
