import React from 'react';
import { Box, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEvents } from '../hooks/useEvents';
import LoadingSpinner from '../components/common/LoadingSpinner';

const ManageEvents: React.FC = () => {
    const { events, deleteEvent, loading, error } = useEvents();
    const navigate = useNavigate();

    if (loading) {
        return <LoadingSpinner />;
    }

    return (
        <Box sx={{ padding: '40px' }}>
            <Typography variant="h4" sx={{ mb: 4, fontWeight: 'bold' }}>
                Manage Events
            </Typography>
            {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
            <Button variant="contained" onClick={() => navigate('/admin/events/new')} sx={{ mb: 2 }}>
                Add Event
            </Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>City</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {events.map((event) => (
                            <TableRow key={event.id}>
                                <TableCell>{event.name}</TableCell>
                                <TableCell>{event.date}</TableCell>
                                <TableCell>{event.city}</TableCell>
                                <TableCell>
                                    <Button onClick={() => navigate(`/admin/events/edit/${event.id}`)}>Edit</Button>
                                    <Button onClick={() => deleteEvent(event.id)}>Delete</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default ManageEvents;
