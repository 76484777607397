import React, { useState, useEffect, ChangeEvent } from 'react';
import { Box, Button, TextField, Typography, CircularProgress, Alert, Checkbox, FormControlLabel } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useEvents } from '../hooks/useEvents';
import LoadingSpinner from '../components/common/LoadingSpinner';
import { config } from '../config';

// Reusable Input Field Component
const StyledTextField = ({ label, name, value, onChange, type = 'text', required = false, multiline = false, rows = 1, readOnly = false }: any) => (
    <TextField
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        type={type}
        fullWidth
        required={required}
        multiline={multiline}
        rows={rows}
        InputProps={{
            readOnly: readOnly,
            sx: {
                borderColor: 'white',
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white',
                },
            },
        }}
        InputLabelProps={{
            shrink: true,
            sx: {
                color: 'text.primary',
            },
        }}
    />
);

const EventForm: React.FC = () => {
    const { eventId } = useParams<{ eventId: string }>();
    const { addEvent, updateEvent, events, loading: eventsLoading, error } = useEvents();
    const [formData, setFormData] = useState({
        name: '',
        date: '',
        city: '',
        location: '',
        description: '',
        ticketsurl: '',
        lineup: '',
        viewpageconversion: '',
        buyticketbuttonconversion: ''
    });
    const [isTBA, setIsTBA] = useState(false);
    const [posterImgFile, setPosterImgFile] = useState<File | null>(null);
    const [previewImgUrl, setPreviewImgUrl] = useState<string>('');
    const [submitLoading, setSubmitLoading] = useState(false);
    const [formError, setFormError] = useState<string | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (eventId && events.length > 0) {
            const existingEvent = events.find((e) => e.id.toString() === eventId);
            if (existingEvent) {
                setFormData({
                    name: existingEvent.name,
                    date: existingEvent.date === 'TBA' ? '' : existingEvent.date,
                    city: existingEvent.city,
                    location: existingEvent.location,
                    description: existingEvent.description,
                    ticketsurl: existingEvent.ticketsurl,
                    lineup: existingEvent.lineup.join(', '),
                    viewpageconversion: existingEvent.viewpageconversion || '',
                    buyticketbuttonconversion: existingEvent.buyticketbuttonconversion || ''
                });
                setIsTBA(existingEvent.date === 'TBA');
                setPreviewImgUrl(existingEvent.posterimgurl);
            }
        }
    }, [eventId, events]);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setPosterImgFile(file);
            const reader = new FileReader();
            reader.onloadend = () => setPreviewImgUrl(reader.result as string);
            reader.readAsDataURL(file);
        }
    };

    const handleTBAChange = (e: ChangeEvent<HTMLInputElement>) => {
        setIsTBA(e.target.checked);
        if (e.target.checked) {
            setFormData((prevData) => ({ ...prevData, date: 'TBA' }));
        } else {
            setFormData((prevData) => ({ ...prevData, date: '' }));
        }
    };

    const handleSubmit = async () => {
        if (Object.values(formData).some((val) => !val && val !== 'TBA') || (!posterImgFile && !eventId)) {
            setFormError('Please fill in all required fields.');
            return;
        }

        setFormError(null);
        setSubmitLoading(true);

        try {
            const submissionData = new FormData();
            Object.entries(formData).forEach(([key, value]) => submissionData.append(key, value));

            if (posterImgFile) submissionData.append('posterimg', posterImgFile);

            const token = localStorage.getItem('token'); // Get the token from local storage

            const response = await fetch(`${config.apiBaseUrl}/api/events/${eventId || ''}`, {
                method: eventId ? 'PUT' : 'POST',
                headers: {
                    Authorization: `Bearer ${token}`, // Include token if required
                    // No need to set 'Content-Type', browser will set this automatically with FormData
                },
                body: submissionData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                setFormError(errorData.error || 'Failed to submit event.');
                return;
            }

            navigate('/admin/events');
        } catch (err) {
            console.error('Error submitting event:', err);
            setFormError('An unexpected error occurred.');
        } finally {
            setSubmitLoading(false);
        }
    };

    if (eventsLoading && eventId) return <LoadingSpinner />;

    return (
        <Box sx={{ padding: '40px', maxWidth: '600px', margin: '0 auto' }}>
            <Typography variant="h4" sx={{ mb: 3 }}>
                {eventId ? 'Edit Event' : 'Add Event'}
            </Typography>
            {formError && <Alert severity="error">{formError}</Alert>}
            {error && <Alert severity="error">{error}</Alert>}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                {/* Reused Input Components */}
                <StyledTextField label="Name" name="name" value={formData.name} onChange={handleInputChange} required />

                {/* TBA Checkbox with Date Field */}
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <StyledTextField
                        label="Date"
                        name="date"
                        value={isTBA ? 'TBA' : formData.date}
                        onChange={handleInputChange}
                        type="date"
                        required
                        readOnly={isTBA}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={isTBA} onChange={handleTBAChange} />}
                        label="Set Date to TBA"
                        sx={{ color: 'text.primary' }}
                    />
                </Box>

                <StyledTextField label="City" name="city" value={formData.city} onChange={handleInputChange} required />
                <StyledTextField label="Location" name="location" value={formData.location} onChange={handleInputChange} required />
                <StyledTextField label="Description" name="description" value={formData.description} onChange={handleInputChange} multiline rows={4} required />
                <StyledTextField label="Tickets URL" name="ticketsurl" value={formData.ticketsurl} onChange={handleInputChange} required />
                <StyledTextField label="Lineup (comma-separated)" name="lineup" value={formData.lineup} onChange={handleInputChange} required />

                {/* Poster Section */}
                <Box>
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                        Poster Image {eventId ? '(Leave blank to keep existing)' : ''}
                    </Typography>
                    {previewImgUrl && (
                        <Box
                            sx={{
                                mb: 2,
                                border: '1px solid white', // Added white border around poster
                                borderRadius: '8px',
                                padding: '4px',
                            }}
                        >
                            <img src={previewImgUrl} alt="Poster Preview" style={{ maxWidth: '100%', height: 'auto', borderRadius: '8px' }} />
                        </Box>
                    )}
                    <Button variant="contained" component="label">
                        Upload Image
                        <input type="file" accept="image/*" hidden onChange={handleImageChange} />
                    </Button>
                </Box>

                {/* Google Ads Section */}
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                    <Typography variant="h5" sx={{ mb: 2 }}>
                        Google Ads Conversion Tracking
                    </Typography>
                    <StyledTextField
                        label="View Page Conversion"
                        name="viewpageconversion"
                        value={formData.viewpageconversion}
                        onChange={handleInputChange}
                    />
                    <StyledTextField
                        label="Buy Ticket Button Conversion"
                        name="buyticketbuttonconversion"
                        value={formData.buyticketbuttonconversion}
                        onChange={handleInputChange}
                    />
                </Box>

                <Button variant="contained" color="primary" onClick={handleSubmit} disabled={submitLoading}>
                    {submitLoading ? <CircularProgress size={24} /> : eventId ? 'Update Event' : 'Add Event'}
                </Button>
            </Box>
        </Box>
    );
};

export default EventForm;
