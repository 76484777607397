import dayjs from 'dayjs';
import { Event } from '../types/Event'; // Adjust the import based on your event type location

export const filterFutureEvents = (events: Event[]): Event[] => {
    const currentDate = dayjs();
    return events.filter(event => event.date === 'TBA' || dayjs(event.date).isAfter(currentDate));
};

export const filterPastEvents = (events: Event[]): Event[] => {
    const currentDate = dayjs();
    return events.filter((event) => dayjs(event.date).isBefore(currentDate));
};
