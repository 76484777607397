import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        background: {
            default: '#000000',
            paper: '#101010',
        },
        text: {
            primary: '#ffffff',
            secondary: '#b0b0b0',
        },
        primary: {
            main: '#ffffff',
        },
    },
    typography: {
        fontFamily: 'Montserrat, Arial, sans-serif', // Set default font family
        h1: {
            fontSize: '2.5rem',
            fontWeight: 'bold',
            textTransform: 'uppercase',
        },
        h2: {
            fontSize: '2rem',
            fontWeight: 'bold',
            textTransform: 'uppercase',
        },
        h6: {
            fontSize: '1.25rem',
            fontWeight: 'bold',
            textTransform: 'uppercase',
        },
        body1: {
            fontSize: '1rem',
            fontWeight: 'normal',
        },
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: 'text.primary',
                },
            },
        },
    }
});

export default theme;
