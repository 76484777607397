import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter as Router} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import {ThemeProvider, CssBaseline} from '@mui/material';
import theme from './theme';
import {AuthProvider} from "./context/AuthContext";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Router>
                <AuthProvider>
                    <App/>
                </AuthProvider>
            </Router>
        </ThemeProvider>
    </React.StrictMode>
);

reportWebVitals();
