import React, { useState } from 'react';
import { Box, Button, Typography, Card, CardActionArea, useMediaQuery } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import { useEvents } from '../hooks/useEvents';
import { filterFutureEvents } from '../utils/eventUtils';
import LoadingSpinner from "./common/LoadingSpinner";

const EventsCarousel: React.FC = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const { events, loading } = useEvents();
    const navigate = useNavigate();

    const isLargeScreen = useMediaQuery('(min-width:1200px)');
    const isMediumScreen = useMediaQuery('(min-width:900px)');
    const isSmallScreen = useMediaQuery('(min-width:600px)');
    const visibleItems = isLargeScreen ? 4 : isMediumScreen ? 3 : isSmallScreen ? 2 : 1;

    const futureEvents = filterFutureEvents(events); // Only get future events

    const handleNext = () => {
        setCurrentSlide((prev) => (prev + 1) % futureEvents.length);
    };

    const handlePrev = () => {
        setCurrentSlide((prev) => (prev - 1 + futureEvents.length) % futureEvents.length);
    };

    const handleEventClick = (eventId: number) => {
        navigate(`/events/${eventId}`);
    };

    if (loading) {
        return <LoadingSpinner />;  // Use the new LoadingSpinner
    }

    return (
        <Box sx={{ position: 'relative', width: '100%', margin: '0 auto', overflow: 'hidden', py: 4 }}>
            <Typography variant="h5" sx={{ textAlign: 'center', mb: 4, fontWeight: 'bold', textTransform: 'uppercase' }}>
                Upcoming Events
            </Typography>

            <Button onClick={handlePrev} sx={{ position: 'absolute', top: '50%', left: '16px', transform: 'translateY(-50%)', color: 'white', zIndex: 1 }}>
                <ArrowBackIosIcon />
            </Button>
            <Button onClick={handleNext} sx={{ position: 'absolute', top: '50%', right: '16px', transform: 'translateY(-50%)', color: 'white', zIndex: 1 }}>
                <ArrowForwardIosIcon />
            </Button>

            <Box sx={{ display: 'flex', transition: 'transform 0.5s ease', transform: `translateX(-${currentSlide * (100 / visibleItems)}%)` }}>
                {futureEvents.map((event) => (
                    <Box key={event.id} sx={{ minWidth: `${100 / visibleItems}%`, flex: '0 0 auto', px: 2 }}>
                        <Card sx={{ position: 'relative', width: '100%', borderRadius: '8px', overflow: 'hidden' }}>
                            <CardActionArea
                                sx={{ position: 'relative', paddingTop: '100%' }}
                                onClick={() => handleEventClick(event.id)}
                            >
                                <img
                                    src={event.posterimgurl}
                                    alt={event.name}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                    }}
                                />
                            </CardActionArea>
                        </Card>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default EventsCarousel;
