import { useState, useEffect } from 'react';
import { config } from '../config';
import { Event } from '../types/Event';

const getAuthHeaders = () => {
    const token = localStorage.getItem('token');
    return {
        Authorization: `Bearer ${token}`,
    };
};

const handleFetch = async (url: string, options: RequestInit) => {
    try {
        const response = await fetch(url, {
            ...options,
            headers: {
                ...options.headers,
                ...getAuthHeaders(),
                'Content-Type': 'application/json', // or multipart/form-data for file uploads
            },
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'An unexpected error occurred.');
        }

        return await response.json();
    } catch (err: any) {
        console.error('Error with request:', err);
        throw new Error(err.message || 'An unexpected error occurred.');
    }
};

interface UseEventsReturn {
    events: Event[];
    loading: boolean;
    error: string | null;
    fetchEvents: () => void;
    addEvent: (formData: FormData) => Promise<void>;
    updateEvent: (id: number, formData: FormData) => Promise<void>;
    deleteEvent: (id: number) => Promise<void>;
}

export const useEvents = (): UseEventsReturn => {
    const [events, setEvents] = useState<Event[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        fetchEvents();
    }, []);

    const fetchEvents = async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await handleFetch(`${config.apiBaseUrl}/api/events`, { method: 'GET' });
            setEvents(data);
        } catch (err: any) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const addEvent = async (formData: FormData) => {
        setError(null);
        try {
            const newEvent: Event = await handleFetch(`${config.apiBaseUrl}/api/events`, {
                method: 'POST',
                body: formData,
            });
            setEvents((prevEvents) => [...prevEvents, newEvent]);
        } catch (err: any) {
            setError(err.message);
        }
    };

    const updateEvent = async (id: number, formData: FormData) => {
        setError(null);
        try {
            const updatedEvent: Event = await handleFetch(`${config.apiBaseUrl}/api/events/${id}`, {
                method: 'PUT',
                body: formData,
            });
            setEvents((prevEvents) =>
                prevEvents.map((event) => (event.id === id ? updatedEvent : event))
            );
        } catch (err: any) {
            setError(err.message);
        }
    };

    const deleteEvent = async (id: number) => {
        setError(null);
        try {
            await handleFetch(`${config.apiBaseUrl}/api/events/${id}`, { method: 'DELETE' });
            setEvents((prevEvents) => prevEvents.filter((event) => event.id !== id));
        } catch (err: any) {
            setError(err.message);
        }
    };

    return {
        events,
        loading,
        error,
        fetchEvents,
        addEvent,
        updateEvent,
        deleteEvent,
    };
};
