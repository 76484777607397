import React, {useContext, useEffect} from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { logout } from '../services/authService';
import {AuthContext} from "../context/AuthContext";

const AdminDashboard: React.FC = () => {
    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!auth?.isAuthenticated) {
            const token = localStorage.getItem('token');
            if (!token) {
                navigate('/login');
            }
        }
    }, [auth?.isAuthenticated, navigate]);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 'calc(100vh - 140px)', backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
            <Box sx={{ maxWidth: '600px', padding: '60px 40px', backgroundColor: '#131313', borderRadius: '10px', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)', textAlign: 'center' }}>
                <Typography variant="h3" sx={{ color: 'white', textTransform: 'uppercase', fontWeight: 'bold', marginBottom: '40px' }}>
                    Admin Dashboard
                </Typography>

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
                    <Button variant="outlined" sx={{ minWidth: '200px', border: '2px solid white', color: 'white' }} onClick={() => navigate('/admin/subscribers')}>
                        Subscribers List
                    </Button>

                    <Button variant="outlined" sx={{ minWidth: '200px', border: '2px solid white', color: 'white' }} onClick={() => navigate('/admin/events')}>
                        Manage Events
                    </Button>

                    <Button variant="outlined" color="error" sx={{ minWidth: '200px', border: '2px solid white', color: 'white' }} onClick={logout}>
                        Logout
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default AdminDashboard;
