import React, { useState } from 'react';
import { Box, Button, TextField, Typography, useTheme } from '@mui/material';
import {config} from "../config";

const Contact: React.FC = () => {
    const theme = useTheme();
    const [formData, setFormData] = useState({ name: '', email: '', message: '' });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await fetch(`${config.apiBaseUrl}/api/contact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error('Failed to send message');
            }

            alert('Message sent successfully!');
        } catch (error) {
            console.error('Error submitting contact form:', error);
            alert('An error occurred. Please try again later.');
        }
    };


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: theme.spacing(5),
                paddingX: theme.spacing(2),
                width: '100%',
                maxWidth: '1200px',
                margin: '0 auto',
            }}
        >
            <Typography
                variant="h4"
                sx={{
                    marginBottom: theme.spacing(3),
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    color: theme.palette.text.primary,
                }}
            >
                Contact Us
            </Typography>

            <Typography
                variant="body1"
                sx={{
                    marginBottom: theme.spacing(4),
                    color: theme.palette.text.secondary,
                }}
            >
                We'd love to hear from you. Feel free to reach out with any inquiries.
            </Typography>

            <Typography
                variant="body1"
                sx={{
                    marginBottom: theme.spacing(4),
                    color: theme.palette.text.secondary,
                }}
            >
                You can also email us at contact@ravershouse.com or message us directly on social media!
            </Typography>

            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    maxWidth: '600px',
                }}
            >
                <TextField
                    label="Name"
                    name="name"
                    fullWidth
                    required
                    value={formData.name}
                    onChange={handleChange}
                    InputLabelProps={{
                        shrink: true, // Ensure label stays above
                    }}
                    sx={{
                        marginBottom: theme.spacing(3),
                        backgroundColor: theme.palette.background.paper,
                        '& input:-webkit-autofill': {
                            WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.paper} inset`,
                            WebkitTextFillColor: theme.palette.text.primary,
                        },
                    }}
                />

                <TextField
                    label="Email"
                    name="email"
                    fullWidth
                    required
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    InputLabelProps={{
                        shrink: true, // Ensure label stays above
                    }}
                    sx={{
                        marginBottom: theme.spacing(3),
                        backgroundColor: theme.palette.background.paper,
                        '& input:-webkit-autofill': {
                            WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.paper} inset`,
                            WebkitTextFillColor: theme.palette.text.primary,
                        },
                    }}
                />

                <TextField
                    label="Message"
                    name="message"
                    fullWidth
                    required
                    multiline
                    rows={4}
                    value={formData.message}
                    onChange={handleChange}
                    InputLabelProps={{
                        shrink: true, // Ensure label stays above
                    }}
                    sx={{
                        marginBottom: theme.spacing(4),
                        backgroundColor: theme.palette.background.paper,
                        '& input:-webkit-autofill': {
                            WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.paper} inset`,
                            WebkitTextFillColor: theme.palette.text.primary,
                        },
                    }}
                />

                <Button
                    type="submit"
                    variant="contained"
                    sx={{
                        padding: theme.spacing(1, 3),
                        textTransform: 'uppercase',
                        backgroundColor: theme.palette.background.paper,
                        color: 'white',
                        '&:hover': {
                            backgroundColor: theme.palette.grey[800],
                        },
                    }}
                >
                    Submit
                </Button>
            </Box>
        </Box>
    );
};

export default Contact;
