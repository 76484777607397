import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const ProtectedRoute = () => {
    const auth = useContext(AuthContext);

    if (!auth) {
        console.log("not authenticated")
        return <Navigate to="/login" />;
    }

    return auth.isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
