import React from 'react';
import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo-bigger.png';

const Navbar: React.FC = () => (
    <AppBar
        position="static"
        sx={{
            backgroundColor: 'black',
            minHeight: '70px',
            borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
        }}
    >
        <Toolbar
            sx={{
                position: 'relative', // Enable relative positioning for the center trick
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                px: 2,
            }}
        >
            {/* Logo */}
            <Box sx={{ display: 'flex', alignItems: 'center', paddingTop: '4px' }}>
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <img
                        src={logo}
                        alt="Ravers House Logo"
                        style={{ height: '60px', marginRight: '20px' }}
                    />
                </Link>
            </Box>

            {/* Centered Navigation Links */}
            <Box
                sx={{
                    position: 'absolute', // Position this box in the center of the toolbar
                    left: '50%',
                    transform: 'translateX(-50%)', // Center horizontally
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 4, // Gap between links
                }}
            >
                <Link to="/events" style={{ textDecoration: 'none' }}>
                    <Typography variant="button" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
                        Events
                    </Typography>
                </Link>
                <Link to="/contact" style={{ textDecoration: 'none' }}>
                    <Typography variant="button" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
                        Contact
                    </Typography>
                </Link>
            </Box>

            {/* Empty Box to keep space on the right side for balance (optional) */}
            <Box sx={{ width: '60px' }} /> {/* Width matches the logo width to balance */}
        </Toolbar>
    </AppBar>
);

export default Navbar;
